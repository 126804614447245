const location: object[] = [
    "Callahan",
    "Comanche",
    "Eastland",
    "Fisher",
    "Haskell",
    "Jones",
    "Mitchell",
    "Nolan",
    "Scurry",
    "Shackleford",
    "Stephens",
    "Stonewall",
    "Taylor",
    "Throckmorton",
].map((item) => ({
    value: item,
    label: item,
}));

export default location;
