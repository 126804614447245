// import en from "./en";
import vi from "./vi";
// import es from "./es";
import en from "./en";
import es from "./es";

export default {
  en,
  vi,
  es
};
